import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  QuickLinkButton,
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Audio Engineering | Jimmy Ether"
        ogDescription="Free music production tutorials including discussions about songwriting philosophy, recording techniques, mixing tips, releasing music, etc."
        image="https://jimmyether.com/images/audio-engineering.jpg"
        url="https://jimmyether.com/audio-engineering/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Audio Engineering</h1>
            <p>
              Record production requires long hours of tedious technical study and practice. You have to understand your gear and software. You have to learn endless tracking and mixing techniques. And, you have develop problem-solving skills under pressure. But, it’s even more important to wrap your head around the abstract nature of recording and mixing. Over the years, I’ve developed conceptual approach to capturing and presenting sound. I’d like to offer a few tips which can help you to identify what makes a great recording.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/audio-engineering.jpg" alt="Audio Engineering" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Audio vs. Film Production</h2>
            <p>
              Recorded sound, particularly in context of album production, is like an extensive fluid collage of aural motion pictures. With film, you typically only show one two-dimensional image from a single lens’ perspective at a time. Shot… cut to another shot… another shot… etc. With sound, you can capture the depth of the space, and by miking several sources, capture several simultaneous perspectives on what was happening at those moments in time. Not only can you capture multiple perspectives, but you can blend them together into an interpretive composition, which — from the listeners perspective — simulates what it felt like to be there when it happened… or alternatively, simulates whatever experience beyond the original performance the artist wants to convey.
            </p>
            <p>
              When we talk about record production, we are essentially talking about layering performance. The initial performance has new performances added to it with the technique of overdubbing. Through this process you start to fold time in on itself like a blanket. The drum, bass and guitar track might have been recorded live in the studio in May. Now it’s June and you’re adding piano, vocals and keyboards. I used to think the goal of multi-track production was to make all these superimposed performances sound like they naturally happen all at once. But that’s as misguided as it is unachievable.
            </p>
            <h2>Embrace the Fiction of Music</h2>
            <p>
              Once you start using more than a binaural pair of microphones played over headphones, you’ve moved away from any semblance of reality. No matter how traditional the music or minimal the technique, you are manufacturing unrealistic space. And that’s not a bad thing, but it’s important to understand what that means from the perspective of the listener.
            </p>
            <p>
              A multi-track recording is a harmonious synchrony of multiple points in time and multiple simultaneous perspectives. When tracking, the point is to capture the source and ambience as best you can under the circumstances. When mixing, the engineer’s job is to reveal the character and space around each perspective (each source) and blend them together in a way which simulates immersion into a unique and magical place. I’m not trying to romanticize it. I mean that quite literally. As the listener, you would have to be in an unusual place ruled by physics which hold no limitation over your ability to simultaneously listen from different places in a room — ear to a kick drum, a snare, inside a bass guitar, speaker cone of an amplifier, throat of a singer, a back wall — and a place ruled by physics which enable you to condense down to a few minutes what, in reality, took hours upon hours (if not days) to achieve.
            </p>
            <h2>The Definition of Great Record Production</h2>
            <p>
              Great records have well crafted songs and engaging moments of performance. They present each perspective of those performances with just enough familiarity that the listener can find their footing in that manufactured world while also providing enough complexity to challenge the listener to move freely to different planes of perspective.
            </p>
            <p>
              Think about a good recording this way: as the listener, you might might be focused on a lead vocal. You hear where it’s positioned between the speakers (or headphones), you hear the ambience around the singer, you are drawn into that space and experience each breath and syllable. All the while, there’s bass pumping, drums pulsing, guitars, keyboards and all these other sounds washing against you… each with their own space. Each recorded source is another plane of perspective for you to move your focus. Each element sounds natural, blending with other sounds happening at that same moment. So even though in reality this type of acoustic listening experience would be impossible, what you experience when listening to a good recording makes sense. You buy into the artificial environment. And I believe much of that acceptance is subconscious. We experience a wide variety of sound all the time, and we subconsciously know how sound naturally behaves. We can also tell when a sound’s behavior is abnormal.
            </p>
            <h2>Putting Aliens in the Room</h2>
            <p>
              The natural element in the mix is critical. I don’t care what your sources are… acoustic, amplified, synths, samples, loops, heavily processed noise… all that is fine. The artificial space created around those sources need to sound believable. Even the most alien sound needs to appear to sit in a realistic space.
            </p>
            <p>
              That, in my opinion, is the entire point of mixing: to balance the alien environment with the natural environment in a way that serves the focal point of the recording. And that’s why I think the English term “balance engineer” is far more fitting for the job than “mix engineer”. Mixing implies blending everything together smoothly into a cake badder… or mush. The balance of the frequencies, position, ambience and dynamic of each source with careful attention to how those sources work together… that’s what should always be on your mind when mixing.
            </p>
            <p>
              Since we all share similar expectations with how sound behaves, adjusting for those balance concerns is basically a clinical activity. Balance engineering is a craft, but it isn’t necessarily a subjective art. There is more or less a range of right and wrong when it comes to presenting sound in a believable way. If something sounds off to me, it’s not up for debate. We make it sound good. The subjective aspects of mixing really come down to highlighting the interesting and understanding the artistic focal point of the sonic presentation. And often, it’s the artist who dictates these choices… or at least tells you when you’re stepping away from their vision. Once you understand the artist’s priorities with a track, you can make that presentation seem natural.
            </p>
            <h2>The Record Production Listening Game</h2>
            <p>
              Try listening to your favorite songs and focus on the record production techniques used to achieve those qualities that you love. Identify what in the presentation sounds natural and what sounds strange. Are the strange aspects off-putting or interesting? Try to listen to the space around each recorded source. How does the separation and blend of those sounds effect your experience with the music and the presentation as a whole? Does each source sound natural, or is it somehow affected in a detrimental way. What’s the recording’s focal point at a given moment? What grabs your attention, and is it good that it does?
            </p>
            <p>
              Producing great records is all a question of balance. Make sure your tracking session capture both the unusual character of your source while also capturing the natural ambient cues people are used to hearing every day. Ask yourself if you have captured enough to work with. And apply that same process of critique during mix-down. Mixing is a constant state of flux. As one small thing is changed, the relationships of everything around it changes. Focus on achieving natural sound from your mix and you’ll easily pick out what isn’t working and make appropriate corrections. First, work to understand the subjective, presentational goals of the song. Then use your innate understanding of aural physics to make the alien combination of sounds, mic perspectives and superimposed performance exist in a believable, seemingly organic space.
            </p>
            <h2>Recent Music Production Tutorials, Articles, and Videos</h2>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>LUFS and The Loudness War</h3>
            <p>
              For decades mastering engineers have fought The Loudness Wars. I deep-dive into why it happenend, if there is any merit, if it is actually over, and how I approach masterting today for streaming and HD.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/lufs-and-the-loudness-war/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-high-def.jpg" alt="LUFS and The Loudness War" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/CADM179.jpg" alt="Demonstration of CADM179 Microphone" />
          </div>
        }
        textRight={
          <div>
            <h3>Demonstration of CADM179 Microphone</h3>
            <p>
              Demonstration and review of the inexpensive CAD M179 Microphone with stereo examples used as a Blumlein pair.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/cad-m179-review/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
